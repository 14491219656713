<template>
	<Popup name="appontements"
	text="<h3>Appontements</h3><br/><div class='popup__statistics'><p class='dark__red'>Quelques chiffres</p><p>6 appontements</p><p>Environ 1000 escales par an</p><p>(une escale peut durer plusieurs jours)</p></div><br/><p>D'un point de vue sécurité, la plateforme a investi dans un système appelé &laquo; drybreak &raquo; constitué de doubles-vannes sur les bras de chargement/déchargement. Cela permet de déconnecter les bras du navire automatiquement et sans fuite possible. De plus chaque année un exercice a lieu en coordination avec le Grand port maritime Nantes Saint-Nazaire et le SDIS 44 (pompiers de la région). Son objectif est de pouvoir faire face à une pénurie d'eau en cas d'incendie. Un navire provenant du port se met à quai afin que les pompiers y branchent leurs tuyaux pour utiliser l'eau transportée par le bateau. Les appontements servent à recevoir du produit brut et à charger les produits finis.</p>"
	video="static/video/bsm.mp4"
	sticker="static/logo-total.jpg">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/appontement_pwgvst_c_scale_w_350.jpg 350w,
		img/popup/appontement_pwgvst_c_scale_w_726.jpg 726w,
		img/popup/appontement_pwgvst_c_scale_w_970.jpg 970w"
		data-src="img/popup/appontement_pwgvst_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/exercice_appontement_wnqtty_c_scale_w_350.jpg 350w,
		img/popup/exercice_appontement_wnqtty_c_scale_w_721.jpg 721w,
		img/popup/exercice_appontement_wnqtty_c_scale_w_970.jpg 970w"
		data-src="img/popup/exercice_appontement_wnqtty_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
